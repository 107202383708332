import { CSSObject, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ColContainer,
  flexColContainerStyles,
  flexRowContainerStyles,
  HideOnMobile,
  RowContainer,
} from 'commonStyles';
import Button from 'components/Button';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { Form } from 'formik';
import { MEDIA_QUERIES } from 'globalStyles';
import { IconCloseDark } from 'images';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputFontSizeShape,
  getThemeMainColor,
  getThemeNeutralColor,
  getThemeShadow,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';

const MEDIA_MAX_HEIGHT_TABLET = '@media (max-height: 800px)';

const fadeOut = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const CloseIcon = styled(IconCloseDark)({
  position: CSS_POSITION.ABSOLUTE,
  cursor: 'pointer',
  margin: '12px',
  zIndex: 10,
  right: 0,
  top: 0,
});

export const AnimationContainer = styled.div({
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  animation: `${fadeOut} 1s`,
  display: CSS_DISPLAY.FLEX,
  height: '100%',
  [MEDIA_QUERIES.TABLET_PORTRAIT]: {
    [MEDIA_MAX_HEIGHT_TABLET]: {
      height: '700px',
    },
  },
  [MEDIA_QUERIES.DESKTOP]: {
    [MEDIA_MAX_HEIGHT_TABLET]: {
      height: '700px',
    },
  },
});

const StyledOverlay = styled.div({
  backgroundColor: 'hsla(0,0%,100%,.95)',
  position: CSS_POSITION.FIXED,
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  zIndex: 11,
  left: 0,
  top: 0,
});

export const Content = styled.div<ITheme>((props) => ({
  boxShadow: `0 0 ${getThemeShadow(props)} rgba(0,0,0,0.1)`,
  backgroundColor: getThemeMainColor(props),
  borderRadius: '12px',
  overflow: 'auto',
  height: '647px',
  width: '840px',
  left: '50%',
  top: '50%',
  ...flexRowContainerStyles,
  [MEDIA_QUERIES.TABLET]: {
    borderRadius: 'unset',
    height: '100%',
    width: '100%',
  },
}));

export const IconWrapper = styled.div({
  position: CSS_POSITION.RELATIVE,
});

export const FeaturesContainer = styled.div<ITheme>((props) => ({
  backgroundColor: getThemeMainColor(props, MAIN_COLOR.BLACK),
  borderBottomLeftRadius: '12px',
  borderTopLeftRadius: '12px',
  minWidth: '354px',
  height: '100%',
  ...HideOnMobile,
}));

export const FormRow = styled(RowContainer)({
  alignItems: CSS_ALIGN.FLEX_END,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
});

export const StyledForm = styled(Form)<ITheme>((props) => ({
  margin: `${getThemeGutter(props, TSHIRT_SIZE.S)} 0 15px`,
  padding: `0 ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
  width: '100%',
  ...flexColContainerStyles,
}));

export const BottomTextWrapper = styled.div<ITheme>((props) => ({
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  '*': {
    fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.S),
    lineHeight: '15px',
  },
  a: {
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  },
}));

export const FormControl = styled(RowContainer)<ITheme>((props) => ({
  p: {
    margin: `0 ${getThemeGutter(props, TSHIRT_SIZE.S)} 0 0`,
    fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
    lineHeight: '15px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
}));

export const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  minWidth: '154px',
  height: '52px',
  [MEDIA_QUERIES.TABLET]: {
    width: 'auto',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: '20px',
    marginTop: '30px',
    width: '100%',
  },
});

export const CtaText = styled.span({
  fontWeight: 500,
  height: '36px',
});

export const ModalContainer = styled(ColContainer)<ITheme>((props) => ({
  padding: getThemeGutter(props, TSHIRT_SIZE.L),
  flex: '1',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: 20,
  },
}));

export const IframeModalContainer = styled(ColContainer)({
  padding: 0,
  position: CSS_POSITION.RELATIVE,
  flex: 1,
  height: '100%',
});

export const Container = styled(ColContainer)({
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  height: '100%',
});

export const LogoWrapper = styled.div({
  alignSelf: CSS_ALIGN.FLEX_START,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    alignSelf: CSS_ALIGN.CENTER,
  },
});

export const StatusIconWrapper = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  height: '96px',
  width: '96px',
  div: {
    height: '100%',
    width: '100%',
  },
}));

export const ConfirmationTitle = styled.div<ITheme>((props) => ({
  '*': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H2),
  },
}));

export const ConfirmationDescription = styled.div<ITheme>((props) => ({
  '*': {
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const ImageWrapper = styled.div<ITheme>({
  justifyContent: CSS_ALIGN.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  display: CSS_DISPLAY.FLEX,
  margin: '50px 0',
});

export const FeaturesIconWrapper = styled.div({
  paddingTop: '1px',
});

export const StyledTitle = styled.div<ITheme>((props) => ({
  color: getThemeMainColor(props),
  marginBottom: '30px',
  lineHeight: '36px',
  fontSize: '24px',
  fontWeight: 500,
}));

export const FeatureContent = styled.div<ITheme>((props) => ({
  padding: `0 30px ${getThemeGutter(props, TSHIRT_SIZE.S)} 30px`,
}));

export const FeatureTitle = styled.div<ITheme>((props) => ({
  lineHeight: '24px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  color: getThemeMainColor(props),
  marginLeft: '15px',
}));

export const FeatureRow = styled(RowContainer)({
  marginTop: '15px',
});

export const Overlay = hideable(StyledOverlay);

const fadeInTransition: CSSObject = {
  visibility: 'visible',
  opacity: 1,
  transition: 'opacity 0.6s linear',
};

const fadeOutTransition: CSSObject = {
  visibility: 'hidden',
  opacity: 0,
  transition: 'visibility 0s 0.6s, opacity 0.6s linear',
};

export const StyledBookingLoader = styled.div<{ isVisible: boolean }>(
  ({ isVisible, ...props }) => ({
    position: CSS_POSITION.ABSOLUTE,
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    alignItems: CSS_ALIGN.CENTER,
    justifyContent: CSS_ALIGN.CENTER,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    borderRadius: '12px',
    background: getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE),
    ...(isVisible ? fadeInTransition : fadeOutTransition),
  })
);

export const StyledBookingIframe = styled.iframe({
  width: '100%',
  height: '100%',
  border: 'none',
  borderRadius: '12px',
});
